import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Step Ups 3×12/leg`}</p>
    <p>{`Ball Hamstring Curls 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`Buy in: 500M Ski Erg`}</p>
    <p>{`4 Rounds of,`}</p>
    <p>{`10-KB Power Cleans per arm (53/35)`}</p>
    <p>{`20-KBS’s (53/35)`}</p>
    <p>{`40-Situps`}</p>
    <p>{`Cash Out: 500M Ski Erg`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`If you are doing the CrossFit Open you have until 8:00pm tonight to
get your scores in!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      